/**
 * @author SPARC PRIVATE LIMITED
 */

import React, { lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import AuthLayout from "./config/auth/AuthLayout";
import ErrorPage from "./config/error/ErrorPage";
import ProtectedRoutes from "./config/protectedRoutes/protectedRoutes";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './redux/Userslice';
// import { RecoilRoot } from "recoil";
import SuspenseFallback from "./config/suspenseFallback/index";
import { AutoLogout, startInterval } from "./services/AuthServices";
// const isLoggedIn = true; 

function App() {
  const user = useSelector(selectUser) !== null ? true : false;
  // const user = useSelector(selectUser) !== true;
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const url = new URL(window.location.href);
  const domain = `${url.hostname}`;

  useEffect(() => {
    if (user) {
      const intervalId = startInterval(() => {
        AutoLogout(dispatch);
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }

  }, [dispatch, user]);

  const LazyLogin = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/login/login")), 1000);
      // setTimeout(() => resolve(import("./pages/login/bjew-login")), 1000);
    });
  });


  const LazyBlankpage = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/blank/blankpage")), 1000);
    });
  });


  const LazyOrganisationDashboard = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/OrganisationDashboard/Orgdashboard")), 1000);
    });
  });
  const LazyProjectDashboard = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/projectDashboard/Dashboard")), 1000);
    });
  });

  const LazySysadmindashboard = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/systemAdminDashboard/dashboard")), 1000);
    });
  });

  const LazyMapView = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/mapview/InitialMapview")), 1000);
    });
  });


  // const Lazydummylogin = lazy(() => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(import("./pages/login/bjew-login")), 1000);
  //   });
  // });


  const LazyUserManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/userManagement/userView")), 1000);
    });
  });

  const LazyroleManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/roleManagement/roleView")), 1000);
    });
  });
  const LazyClientManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/clientManagement/clientview.jsx")), 1000);
    });
  });
  const LazyLandSchuduelManagemnet = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/LandSchedule/landSchedule")), 1000);
    });
  });
  const LazyMenuManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/MenuManagemnet/menuView")), 1000);
    });
  });
  const LazySubscriptionManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/subscriptionManagement/subscription")), 1000);
    });
  });
  const LazyClientSubscriptionManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/clientSubscriptionManagement/clientSubscription")), 1000);
    });
  });
  const LazyPhaseManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/PhaseManagement/Phase")), 1000);
    });
  });
  const LazyLandClassStage = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/LandClassStage/landClass")), 1000);
    });
  });
  const LazyProjectManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/projectManagement/project")), 1000);
    });
  });

  const LazyProjectLayout = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/ProjectLayoutManagement/ProjectLayout")), 1000);
    });
  });
  
  const LazyClientMenuMapping = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/clientMenuMapping/clientMenuMapping")), 1000);
    });
  });

  const Lazydesignation = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/designation/designationlist")), 1000);
    });
  });
  const LazyClientPaymentManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/clientPaymentManage/clientPayment")), 1000);
    });
  });
  const LazyclientBillManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/clientBillManagement/clientBillList")), 1000);
    });
  });
  const LazyStructuralHierachyManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/structuralHierachyManagement/structuralHierachyView")), 1000);
    });
  });
  const LazyMapFrameView = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./components/mapview/mapFrameView")), 1000);
    });
  });
  const LazyProcessing = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/login/processing")), 1000);
    });
  });


  const LazyroleMenu = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/roleMenuList/roleMenu")), 1000);
    });
  });
  const LazyKhatiyanManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/KhatiyanManage/Khatiyan")), 1000);
    });
  });
  const LazyUploadLandSchedule = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./components/project-component/LandScheduleUpload")), 1000);
    });
  });
  const LazyReportManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/reportManagement/report")), 1000);
    });
  });
  // const LazyPrivacyPolicy = lazy(() => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(import("./pages/privacypolicy/policy")), 1000);
  //   });
  // });

  const LazyPrivacyPolicy = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/login/privacypolicy/policy.jsx")), 1000);
    });
  });
  const LazyAbout = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/login/about.jsx")), 1000);
    });
  });



  const allowedPaths = user ? userData.menu.reduce((paths, item) => {
    if ((item.module === "/" || item.module === null) && item.child.length > 0) {
      paths.push(item.module); // Include parent module
      item.child.forEach(childItem => {
        paths.push(childItem.module); // Include child modules
      });
    } else {
      paths.push(item.module); // Include regular modules
    }
    return paths;

  }, []) : [];

  const isPathAllowed = (path) => {
    return allowedPaths.includes(path);
  }

  const LazyleaseCase = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/leaseCase/leasecaseList")), 1000);
    });
  });
  const Lazylegalcase = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/legalCase/legalcaselist")), 1000);
    });
  });
  // console.log(user);
  const LazyTenant = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/tenant/tenantList")), 1000);
    });
  });
  const LazyAwardManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/awardManagement/Award")), 1000);
    });
  });
  const LazyGrievance = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/grievance/grievanceList")), 1000);
    });
  });
  const LazyCost = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/costEstimation/costEstimationList")), 1000);
    });
  });
  const LazyProjectIntersect = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/projectIntersect/projectIntersect")), 1000);
    });
  });
  const LazyDMS = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/documentManagement/documentManagement")), 1000);
    });
  });
  const LazyYadastSurvey = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/yadastSurvey/yadastSurveyList")), 1000);
    });
  });
  const LazyGovtVoucherPayment = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/govtPaymentVoucher/govtPaymentVoucherList")), 1000);
    });
  });
  const LazyPaymentVoucherPayment = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/paymentVoucher/paymentVoucher")), 1000);
    });
  });
  const LazyRRPolicyManagement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/R&RPolicyManagement/RRPolicy")), 1000);
    });
  });
  const LazyForestLand = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("././pages/forestLandSchedule/forestLand")), 1000);
    });
  });
  const LazyFdpCase = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("././pages/forestDiverionProposal/fdpCase")), 1000);
    });
  });
  const LazyRREntitlement = lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/RREntitlementManage/r&REntitlement")), 1000);
    });
  });
 
  
  return (
    <div>

      <BrowserRouter>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Routes>  

            {/* for la-tracker  */}
            <Route path="/" element={user ? (userData?.userDetails?.orgId ? (isPathAllowed('/dashboard') ? <Navigate to="/dashboard" /> : <Navigate to="/dashboard" />) : <Navigate to="/sysadmindashboard" />) : (
              (domain === 'la-tracker.com' ? <HomePage /> :  <LazyLogin />) // Redirect to ExternalPage if user is not logged in
            )} />

            {/* for bjew */}
            {/* <Route path="/" element={user ? (userData?.userDetails?.orgId ? (isPathAllowed('/dashboard') ? <Navigate to="/dashboard" /> : <Navigate to="/dashboard" />) : <Navigate to="/sysadmindashboard" />) : <Navigate to="/login" />} /> */}
            <Route path="/homepage" element={<HomePage />} />
            <Route path="/processing" element={<LazyProcessing />} />
            <Route path="/privacypolicy" element={<LazyPrivacyPolicy />} />
            <Route path="/privacy" element={<LazyAbout />} />
            <Route path="/login" element={user ? <Navigate to="/processing" /> : <LazyLogin />} />
            <Route path="/error/:errorType" element={<ErrorPage />} />
            <Route path="/accessDenied" element={<ErrorPage errorType='Oops! Access Denied' />} />

            <Route path="*" element={<ErrorPage />} /> {/* Redirect to error page for non-existing routes */}
            <Route path="/geoserver/*" element={<Navigate to="/geoserver" />} />
            <Route path="/documents/*" element={user ? <Navigate to="/documents" /> : <LazyLogin />} />
            <Route path="/var/www/html/saas-documents_stg/*" element={user ? <Navigate to="/var/www/html/saas-documents_stg" /> : <LazyLogin />} />
            <Route path="/var/www/html/saas-documents_prod/*" element={user ? <Navigate to="/var/www/html/saas-documents_prod" /> : <LazyLogin />} />
            <Route element={<ProtectedRoutes auth={user} />}>

              <Route path="/dashboard" element={userData?.userDetails?.orgId ? (isPathAllowed('/dashboard') ? <LazyProjectDashboard /> : <Navigate to="/accessDenied" />) : <Navigate to="/sysadmindashboard" />} />
              <Route path="/report" element={<LazyReportManagement />} />
              <Route path="/sysadmindashboard" element={<LazySysadmindashboard />} />
              <Route path="/userManagement" element={isPathAllowed('/userManagement') ? <LazyUserManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/organizationManagement" element={isPathAllowed('/organizationManagement') ? <LazyClientManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/admin" element={isPathAllowed('/admin') ? <LazyUserManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/projectManagement" element={isPathAllowed('/projectManagement') ? <LazyProjectManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/projectLayout" element={isPathAllowed('/projectLayout') ? <LazyProjectLayout /> : <Navigate to="/accessDenied" />} />
              <Route path="/menuManagement" element={isPathAllowed('/menuManagement') ? <LazyMenuManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/roleManagement" element={isPathAllowed('/roleManagement') ? <LazyroleManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/roleMenu" element={isPathAllowed('/roleMenu') ? <LazyroleMenu /> : <Navigate to="/accessDenied" />} />
              <Route path="/designation" element={isPathAllowed('/designation') ? <Lazydesignation /> : <Navigate to="/accessDenied" />} />
              {/* <Route path="/subscription" element={isPathAllowed('/subscription') ? <LazySubscriptionManagement /> : <Navigate to="/accessDenied" />} /> */}
              <Route path="/clientSubscription" element={isPathAllowed('/clientSubscription') ? <LazyClientSubscriptionManagement /> : <Navigate to="/accessDenied" />} />
              {/* <Route path="/clientBillManagement" element={isPathAllowed('/clientBillManagement') ? <LazyclientBillManagement /> : <Navigate to="/accessDenied" />} /> */}
              <Route path="/clientBillManagement" element={<LazyclientBillManagement />} />

              {/* <Route path="/designation" element={isPathAllowed('/designation') ? <LazyUserManagement /> : <Navigate to="/accessDenied" />} /> */}

              {/* <Route path="/subscription" element={<LazySubscriptionManagement />} /> */}
              {/* <Route path="/leaseCase" element={<LazyleaseCase />} /> */}
              {/* <Route path="/landClassStage" element={<LazyLandClassStage />} /> */}
              {/* <Route path="/clientPaymentManagement" element={<LazyClientPaymentManagement />} /> */}
              {/* <Route path="/structuralHierarchy" element={<LazyStructuralHierachyManagement />} /> */}
              {/* <Route path="/KhatiyanManagement" element={<LazyKhatiyanManagement />} /> */}
              {/* <Route path="/tenant" element={<LazyTenant />} /> */}
              <Route path="/AwardManagement" element={<LazyAwardManagement />} />
              <Route path="/projectIntersect" element={<LazyProjectIntersect />} />
              <Route path="/dms" element={<LazyDMS />} />
              {/* </Route>
               
            </Routes>
          </React.Suspense>
        </BrowserRouter> */}

              <Route path="/legalCase" element={<Lazylegalcase />} />
              <Route path="/blankpage" element={<LazyBlankpage />} />
              <Route path="/orgdashboard" element={<LazyOrganisationDashboard />} />
              <Route path="/mapview" element={<LazyMapView />} />
              <Route path="/phaseManagement" element={isPathAllowed('/phaseManagement') ? <LazyPhaseManagement /> : <Navigate to="/accessDenied" />} />
              <Route path="/clientMapping" element={<LazyClientMenuMapping />} />
              <Route path="/landSchedule" element={<LazyLandSchuduelManagemnet />} />

              <Route path="/subscription" element={<LazySubscriptionManagement />} />
              <Route path="/leaseCase" element={<LazyleaseCase />} />
              <Route path="/landClassStage" element={<LazyLandClassStage />} />
              <Route path="/clientPaymentManagement" element={<LazyClientPaymentManagement />} />
              <Route path="/structuralHierarchy" element={<LazyStructuralHierachyManagement />} />
              <Route path="/mapFrame" element={<LazyMapFrameView />} />
              <Route path="/KhatiyanManagement" element={<LazyKhatiyanManagement />} />
              <Route path="/tenant" element={<LazyTenant />} />
              <Route path="/uploadLandSchedule/*" element={<LazyUploadLandSchedule />} />
              <Route path="/support" element={<LazyGrievance />} />
              <Route path="/costEstimation" element={<LazyCost />} />
              <Route path="/yadastSurvey" element={<LazyYadastSurvey />} />
              <Route path="/govtPayment" element={<LazyGovtVoucherPayment />} />
              <Route path="/paymentVoucher" element={<LazyPaymentVoucherPayment />} />
              <Route path="/RRPolicy" element={<LazyRRPolicyManagement />} />
              <Route path="/forestLand" element={<LazyForestLand />} />
              <Route path="/fdCase" element={<LazyFdpCase />} />
              <Route path="/rrEntitlement" element={<LazyRREntitlement />} /> 
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>

    </div>
  );
}

// Component to render your external HTML page
const HomePage = () => {
  return ( 
      <iframe
        src="/index-home.html"
        title="External Page"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      /> 
  );
};
export default App;
